<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-card rounded="lg" flat outlined class="pa-6 full_w">
        <div v-if="!loading && dataLayanan">
          <div>
            <h4 class="not_bold">Jenis Layanan</h4>
            <v-row no-gutters align="center" justify="space-between">
              <v-col cols="12" md="8" class="mt-2">
                <v-select
                  placeholder="Pilih jenis layanan"
                  outlined
                  hide-details
                  :items="dataLayanan"
                  item-text="layanan"
                  item-value="id"
                  v-model="jenis_layanan"
                  dense
                  @change="
                    fetchDataSubTimeline(jenis_layanan)
                    "
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" class="mt-2">
                <v-row no-gutters justify="end">
                  <v-col cols="12" md="6">
                    <v-btn
                      block
                      class="text-capitalize pa-2 d-flex align-center bg_color1"
                      depressed
                      outlined
                      dark
                      @click="createDataLayanan"
                    >
                      <v-icon small>mdi-plus</v-icon>
                      <h5>Tambah</h5>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                      block
                      class="text-capitalize pa-2 d-flex align-center"
                      depressed
                      @click="deleteDataLayanan"
                      style="background: red;"
                    >
                      <v-icon color="white" size="25">mdi-delete-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3">
            <v-row
              no-gutters
              class="rounded-lg"
              style="
                border-left: 1px solid #e9ecf1;
                border-right: 1px solid #e9ecf1;
                border-bottom: 1px solid #e9ecf1;
              "
            >
              <v-col cols="12" md="12" class="mt-2">
                <v-card
                  flat
                  rounded="0"
                  class="bg_color1 d-flex justify-center rounded-t-lg"
                  dark
                >
                  <v-card-title>Sub Timeline</v-card-title>
                </v-card>
                <div v-for="(item, n) in subTimeline" :key="n">
                  <v-card
                    flat
                    rounded="0"
                    class="mb-1 pa-2 d-flex align-center justify-center flex-column"
                    color="#E9ECF1"
                  >
                    <div style="width: 100%">
                      <div class="d-flex px-2 align-center">
                        <h4 style="flex-grow: 1">{{ item.step }}</h4>
                        <v-btn
                          icon
                          v-if="item.created_by != null"
                          @click="deleteData(item)"
                        >
                          <v-icon color="black">mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </div>
                <v-card
                  flat
                  rounded="0"
                  class="pa-2 d-flex align-center justify-center flex-column rounded-bl-lg"
                  color="#E9ECF1"
                >
                  <div style="width: 100%">
                    <div class="d-flex px-2 align-center">
                      <v-text-field
                        v-model="custom_subtimeline"
                        placeholder="Custom"
                        hide-details
                        dense
                        class="mr-2"
                        flat
                        solo
                        style="background: white"
                      ></v-text-field>
                      <v-btn
                        :disabled="jenis_layanan ? false : true"
                        @click="addSubTimeline(-2)"
                        class="text-capitalize bg_color1"
                      >
                        <h4 style="color: white">Tambah</h4>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div class="mt-6 d-flex align-center justify-end">
            <v-menu transition="slide-y-transition" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="text-capitalize mr-2"
                  min-width="150px"
                  depressed
                  outlined
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  <h4 style="color: black">Reset</h4>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn
                    block
                    class="text-capitalize"
                    depressed
                    @click="resetType('all')"
                  >
                    <h4 style="color: black">Reset All</h4>
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    class="text-capitalize"
                    depressed
                    @click="resetType('page')"
                    :disabled="jenis_layanan ? false : true"
                  >
                    <h4 style="color: black">Reset {{ nama_layanan!=""?nama_layanan:' layanan' }}</h4>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div v-if="loading">
          <v-skeleton-loader
            v-for="(item, n) in 3"
            :key="n"
            type="article"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </div>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="d_type != 'd_reset'?fetchDataSubTimeline(jenis_layanan):resetFunction(type_reset)"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchDataLayanan"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
import DialogMedium from '../Dialog/dialogMedium.vue';
import DialogSmall from '../Dialog/dialogSmall.vue';
export default {
  components: { bullet, NavbarProfile, DialogSmall, DialogMedium },
  name: "setupPendaftaran",
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      type_reset: "all",
      loading: true,
      loadingButton: false,
      dataLayanan: [],
      subTimeline: [],
      custom_subtimeline: "",
      dataSet: null,
      d_create: false,
      d_small: false,
      line: "",
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      edit: false,
      preview: null,
      linkPendaftaran: "",
      jenis_layanan: "",
      nama_layanan: "",
      nama_client: "",
      id_klien: "",
      tujuan_event: "",
      tipe_klien: "",
      tab: 0,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Set Up Layanan Event",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchDataLayanan();
  },
  methods: {
    createDataLayanan() {
      this.d_type = "c_layanan";
      this.d_title = "Tambah Layanan";
      this.d_create = true;
      this.line = true;
    },

    deleteDataLayanan() {
      this.d_type = "d_layanan";
      this.d_title = "Delete Layanan";
      this.inventory = this.dataLayanan;
      this.d_create = true;
      this.line = true;
    },

    fetchDataLayanan() {
      this.dataLayanan= [],
      this.subTimeline= [],
      this.loading = true;
      let data = {
        path: `sysadmin/event/daftar-layanan`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataLayanan = data;
          this.nama_layanan = "";
          this.jenis_layanan = "";
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchDataSubTimeline(idx) {
      let indexData = this.dataLayanan.findIndex(item => item.id === idx);

      this.loading = true;
      let data = {
        path: `sysadmin/event/daftar-step/${idx}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          console.log(indexData);
          this.nama_layanan = this.dataLayanan[indexData]['layanan'];
          this.subTimeline = data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    addSubTimeline() {
      let data = {
        body: {
          event_layanan_id: this.jenis_layanan,
          step: this.custom_subtimeline,
        },
        path: `sysadmin/event/daftar-step/custom-admin`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.custom_subtimeline = "";
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          this.fetchDataSubTimeline(this.jenis_layanan);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },

    // delete list dokumen tapi ngga jadi
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/event/daftar-step/custom-admin/delete`,
        deleteType: "Sub timeline",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Sub Timeline";
      this.d_small = true;
      this.line = true;
    },

    resetType(item) {
      this.type_reset = item;
      this.inventory = {
        type: item,
      };
      this.d_type = "d_reset";
      this.d_title = "Reset Data";
      this.d_small = true;
      this.line = true;
    },

    resetFunction(){
      let body = {};
      if(this.jenis_layanan != ""){
        body = {
          id: this.jenis_layanan,
        };
      }

      let data = {
        body: body,
        path: `sysadmin/event/daftar-step/reset`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.custom_subtimeline = "";
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          if(this.type_reset == 'all'){
            this.jenis_layanan = '';
            this.subTimeline = [];
          }else{
            this.fetchDataSubTimeline(this.jenis_layanan);
          }
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    }
  },
};
</script>

<style scoped>
.theme--light.v-card.v-card--outlined {
  border-bottom: none;
}
</style>
